import SiteModel from './SiteModel';
import { SiteResponseModel } from './response/SiteResponse';

const mapSiteResponseToModel = (site: SiteResponseModel): SiteModel => ({
  id: site.id,
  siteId: site.siteId,
  name: site.name,
  isDefault: site.isDefault,
});

export default mapSiteResponseToModel;
