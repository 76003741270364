import moment from 'moment';

export enum CampaignEnum {
  EVENT = 'Event',
  PROMOTION = 'Promotion',
  GAMIFICATION = 'Gamification',
}

interface CampaignModel {
  id: string;
  contentId: string;
  channel: 'webUI' | 'mobile';
  eventBg: string;
  eventLogo: null | string;
  expirationDate: moment.Moment;
  startDate: moment.Moment;
  language: string;
  message: string;
  plainTextMessage: string;
  subject: string;
  read: boolean;
  type: CampaignEnum;
}

export default CampaignModel;
