
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import AtomCardWaypoint from '@/components/atoms/AtomCardWaypoint.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';
import RideDetailsType from '@/enums/rides/RideDetailsTypeEnum';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';

import { showParkingInformation, getParkingIcon } from './ride-details/OrganismDetailsService';

export default Vue.extend({
  name: 'OrganismUnMatchedRide',
  components: {
    AtomCardWaypoint,
    AtomSvgIcon,
    MoleculeCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    ride: {
      type: Object as PropType<RideSummaryModel>,
      required: true,
    },
    hasAlternatives: {
      type: Boolean,
      default: false,
    },
    isPartOfSeries: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    CardVariant,
  }),
  methods: {
    redirectToDetails(id: string) {
      this.$router.push({
        name: 'RideDetails',
        params: { id, type: RideDetailsType.UNMATCHED_RIDE },
      });
    },
  },
  computed: {
    ...mapGetters('user', ['features']),
    ...mapGetters('rides', {
      getIntentById: 'getIntentById',
    }),
    isPastEvent(): boolean {
      const { eventType } = this.ride;
      return eventType === EventTypeEnum.PAST_MATCH || eventType === EventTypeEnum.PAST_RIDE_INTENT;
    },
    rideIntent(): RideIntentModel {
      return this.getIntentById(this.ride.intentId);
    },
    showParkingDetails(): boolean {
      return showParkingInformation(
        this.ride.role,
        this.features,
        !!this.rideIntent.destination.providesParkingPlace,
      );
    },
    parkingIcon(): string {
      return getParkingIcon(this.rideIntent, moment());
    },
  },
});
