
import Vue from 'vue';
import { mapActions } from 'vuex';
import VueLottiePlayer from 'vue-lottie-player';
import GeoCodeModel from '@twogo/geolocation/src/lib/models/location/GeoCodeModel';
import { CoordinatesModel } from '@/models/common/CoordinatesModel';
import { browserGeolocation, geoCode, revGeoCode } from '@/services/geolocation/geolocationService';
import { getGenericAddress } from '@/services/geolocation/genericAddressBuilder';
import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import { createMarkerBase } from '@twogo/here-maps/src/lib/HereMapsService';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomDropdownAddressSearch from '@/components/atoms/AtomDropdownAddressSearch.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';
import OrganismFirstStepsSkeleton from '@/components/organisms/first-steps/OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsAddressSkeleton',
  components: {
    VueLottiePlayer,
    AtomButton,
    AtomTextInput,
    AtomDropdownAddressSearch,
    MoleculeHereMap,
    OrganismFirstStepsSkeleton,
  },
  props: {
    animationData: {
      type: Object,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    inputIcon: {
      type: String,
      required: true,
    },
    inputPlaceholder: {
      type: String,
      required: true,
    },
    hasMeetingPoint: {
      type: Boolean,
      default: false,
    },
    buttonContent: {
      type: String,
      required: true,
    },
    poiName: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return ({
      ButtonVariant,
      showMap: false,
      comment: '',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      address: undefined as unknown as GenericAddressModel | undefined,
      isNameFieldValid: true,
      isNameInUse: false,
      disableNameInput: false,
      geoSuggestions: [] as GeoCodeModel[],
    });
  },
  computed: {
    getMarker(): BaseMarkerModel[] {
      const { address } = this;
      const markers = [] as BaseMarkerModel[];
      if (address) {
        markers.push(createMarkerBase(locationPin, address.position, 'create-POI'));
      }
      return markers;
    },
  },
  methods: {
    ...mapActions('poi', ['addPoi']),
    getGenericAddress,
    async getGeoSuggestions(location: string): Promise<void> {
      this.showMap = true;
      if (location.length >= 3) {
        const { data } = await geoCode({ query: location });
        this.geoSuggestions = data;
      } else {
        this.geoSuggestions = [];
      }
    },
    async searchAddressByCoordinates(coordinates: CoordinatesModel): Promise<void> {
      const {
        data: [address],
      } = await revGeoCode({ at: coordinates });
      this.address = getGenericAddress(address);
    },
    async localizeMe(): Promise<void> {
      const { success, coordinates, message = '' } = await browserGeolocation();
      if (success && coordinates) {
        this.searchAddressByCoordinates(coordinates);
      } else {
        toast(this.$bvToast, message, ToastVariants.DANGER);
      }
    },
    submitSelection(): void {
      this.$emit('selection', this.address);
      this.$emit('next', false);
    },
    setComment(value: string): void {
      this.comment = value;
    },
    setAddress(value: GenericAddressModel): void {
      this.address = getGenericAddress(value);
    },
  },
});
