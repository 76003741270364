
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import OrganismFirstStepsSkeleton from './OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsGPS',
  components: { VueLottiePlayer, AtomButton, OrganismFirstStepsSkeleton },
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    ButtonVariant,
  }),
  methods: {
    init() {
      if (!navigator.geolocation) {
        this.$emit('next', true);
      }
    },
    triggerGeolocation() {
      navigator.geolocation.getCurrentPosition(
        () => {
          this.$emit('next', false);
        },
        (error) => {
          // TODO: Show a error message (maybe)
          console.log(error);
        },
      );
    },
  },
  watch: {
    isShown() {
      if (this.isShown) {
        this.init();
      }
    },
  },
});
