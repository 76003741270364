
import Vue, { PropType } from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import { mapActions, mapGetters } from 'vuex';
import AtomSelectButton from '@/components/atoms/AtomSelectButton.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import MoleculeModal, { ModalSize } from '@/components/molecules/modal/MoleculeModal.vue';
import SiteModel from '@/models/site/SiteModel';

import { assignSite, addUserProperty } from '@/api/user/userApi';

import { getBrandedCompanyLogo, getBrandingById } from '@/services/branding/BrandingService';

export default Vue.extend({
  name: 'OrganismSiteAssignmentModal',
  components: { VueLottiePlayer, AtomSelectButton, AtomText, AtomTextInput, AtomSvgIcon, MoleculeModal },
  props: {
    id: {
      type: String,
      required: true,
    },
    sites: {
      type: Array as PropType<SiteModel[]>,
      default: (): SiteModel[] => [],
    },
  },
  data: () => ({
    searchCriteria: '',
    nrOfSitesOnTheList: 10,
    loading: false,
    ModalSize,
  }),
  computed: {
    ...mapGetters('user', ['getUserId', 'site', 'user', 'properties']),
    assignedSiteId(): number|undefined {
      return this.site ? this.site.siteId : undefined;
    },
    getCompanyLogo(): string {
      const company = getBrandingById(this.site?.companyId);
      return company ? getBrandedCompanyLogo(company) : '';
    },
    hasCompletedSiteAssignment(): boolean {
      return this.properties.hasCompletedSiteAssignment && this.properties.hasCompletedSiteAssignment === 'true';
    },
    paginatedSites(): SiteModel[] {
      if (this.searchCriteria) {
        return this.sites
          .filter(
            (s) => s.name.toLocaleLowerCase().includes(this.searchCriteria.toLocaleLowerCase()),
          )
          .slice(0, this.nrOfSitesOnTheList);
      }
      if (this.sites.length > this.nrOfSitesOnTheList) {
        return this.sites.slice(0, this.nrOfSitesOnTheList);
      }
      return this.sites;
    },
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    async assignSite(siteId: string) {
      this.$bvModal.hide('tg-modal--user-site-assignment');
      const { status } = await assignSite(this.getUserId, siteId);

      if (status === 201 || status === 200) {
        await addUserProperty(this.getUserId, { key: 'hasCompletedSiteAssignment', value: 'true' });
        await this.fetchUser(this.getUserId);
      } else {
        toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
      }
    },
    setSearchCriteria(criteria: string): void {
      this.searchCriteria = criteria;
    },
  },
});
