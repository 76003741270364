
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { getUserProfile, updateUserProfile } from '@twogo/cidaas/src/lib/CidaasService';
import AtomProgressBar from '@/components/atoms/AtomProgressBar.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import { createUser } from '@/api/user/userApi';
import { CidaasUserData } from '@/store/modules/user/state';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import OrganismFirstStepsWelcome from './OrganismFirstStepsWelcome.vue';
// import OrganismFirstStepsNotification from './OrganismFirstStepsNotification.vue';
import OrganismFirstStepsGPS from './OrganismFirstStepsGPS.vue';
import OrganismFirstStepsHome from './OrganismFirstStepsHome.vue';
import OrganismFirstStepsWork from './OrganismFirstStepsWork.vue';
import OrganismFirstStepsPhone from './OrganismFirstStepsPhone.vue';
import OrganismFirstStepsProfilePicture from './OrganismFirstStepsProfilePicture.vue';
import OrganismFirstStepsFinished from './OrganismFirstStepsFinished.vue';

export default Vue.extend({
  name: 'OrganismFirstSteps',
  components: {
    AtomProgressBar,
    MoleculeModal,
    OrganismFirstStepsWelcome,
    // OrganismFirstStepsNotification,
    OrganismFirstStepsGPS,
    OrganismFirstStepsHome,
    OrganismFirstStepsWork,
    OrganismFirstStepsPhone,
    OrganismFirstStepsProfilePicture,
    OrganismFirstStepsFinished,
  },
  props: {
    callToActionId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    isModalSticky: true,
    currentStateId: 0,
    componentNames: [
      'OrganismFirstStepsWelcome',
      // 'OrganismFirstStepsNotification',
      'OrganismFirstStepsGPS',
      'OrganismFirstStepsHome',
      'OrganismFirstStepsWork',
      // 'OrganismFirstStepsPhone',
      'OrganismFirstStepsProfilePicture',
      'OrganismFirstStepsFinished',
    ],
    home: {} as GenericAddressModel,
    work: undefined as GenericAddressModel|undefined,
    avatar: undefined as string|undefined,
    phone: undefined as string|undefined,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('user', ['getCidaasUserData', 'getAccessToken', 'getUserId', 'registrationToken']),
  },
  methods: {
    ...mapActions('user', ['fetchUser', 'setCidaasUserData']),
    async finish(skipped: boolean) {
      const cidaasUser = this.getCidaasUserData as CidaasUserData;
      this.isLoading = true;
      const { status } = await createUser({
        userId: this.getUserId,
        firstName: cidaasUser.givenName,
        lastName: cidaasUser.familyName,
        timeLocale: cidaasUser.locale,
        homeAddress: {
          comment: '',
          label: this.home.address.label,
          name: 'HOME',
          location: {
            latitude: this.home.position.lat,
            longitude: this.home.position.lng,
          },
        },
        workAddress: this.work ? {
          comment: '',
          label: this.work.address.label,
          location: {
            latitude: this.work.position.lat,
            longitude: this.work.position.lng,
          },
        } : null,
        locale: cidaasUser.locale, // Ex. en_US
        unitOfLengthLocale: cidaasUser.locale, // Ex. en_US
        siteToken: this.registrationToken,
        rideSettings: {
          maxDetourMinutes: 15,
          minSharedDurationPercentage: 0,
          role: 'passenger',
          lightSequence: true,
        },
        image: this.avatar,
      });
      this.isLoading = false;
      if (status === 200) {
        await updateUserProfile(this.getCidaasUserData, this.getAccessToken, this.getUserId);
        const userProfile = await getUserProfile(this.getAccessToken);
        if (!userProfile.error) {
          this.setCidaasUserData(userProfile);
        }
        await this.fetchUser(this.getUserId);
        if (this.callToActionId) {
          if (skipped) {
            this.$router.push(`/${this.$i18n.locale}/home`);
          }
          this.hideModal();
        } else if (this.currentStateId === 5) {
          this.hideModal();
        } else {
          this.currentStateId += 1;
        }
        this.$emit('completed');
      } else {
        toast(
          this.$bvToast,
          this.$t('rideIntent.messages.error').toString(),
          ToastVariants.DANGER,
        );
      }
    },
    nextStep(skipped: boolean) {
      if (this.callToActionId) {
        if (skipped) {
          this.$router.push(`/${this.$i18n.locale}/home`);
        }
        this.hideModal();
      } else if (this.currentStateId === 5) {
        this.hideModal();
      } else {
        this.currentStateId += 1;
      }
    },
    hideModal() {
      this.isModalSticky = false;
      this.$nextTick(() => this.$bvModal.hide('tg-modal--first-steps'));
    },
    isStepShown(index: number) {
      if (this.callToActionId) {
        this.currentStateId = this.callToActionId;
      }
      return this.currentStateId === index;
    },
    setSelection(component: string, value: any) {
      if (component === this.componentNames[2]) {
        this.home = value;
      } else if (component === this.componentNames[3]) {
        this.work = value;
      } else if (component === this.componentNames[4]) {
        this.avatar = value;
      }
    },
  },
});
