
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomRouterLink from '@/components/atoms/AtomRouterLink.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import { getBrandingById, injectBrandingIntoDocument } from '@/services/branding/BrandingService';
import { addUserProperty, assignSiteByToken } from '@/api/user/userApi';

export default Vue.extend({
  name: 'OrganismEnterCode',
  components: {
    MoleculeModal,
    AtomText,
    AtomRouterLink,
    AtomTextInput,
    AtomButton,
  },
  data: () => ({
    token: '',
    loading: false,
    sticky: true,
    ButtonVariant,
    ToastVariants,
    isTokenValid: true,
  }),
  computed: {
    ...mapGetters('user', ['getUserId', 'site', 'user', 'properties']),
  },
  methods: {
    ...mapActions('user', ['fetchUser']),
    async skipAssignment() {
      this.sticky = false;
      await addUserProperty(this.getUserId, { key: 'hasCompletedOrganizationAssignment', value: 'true' });
      await this.fetchUser(this.getUserId);
      this.$bvModal.hide('tg-modal--enter-code');
    },
    async assignToOrganization() {
      this.sticky = false;
      this.loading = true;
      const { status, data: user } = await assignSiteByToken(this.getUserId, this.token);
      if (user && (status === 201 || status === 200)) {
        await addUserProperty(this.getUserId, { key: 'hasCompletedOrganizationAssignment', value: 'true' });
        await this.fetchUser(this.getUserId);
        const brand = getBrandingById(user.site.companyId.toString());

        if (brand) injectBrandingIntoDocument(brand);

        this.$emit('success');
        this.$bvModal.hide('tg-modal--enter-code');
      } else {
        this.$emit('error');
        this.isTokenValid = false;
        toast(this.$bvToast, this.$t('profile.messages.error').toString(), ToastVariants.DANGER);
      }
      this.loading = false;
    },
  },
});

