
import Vue from 'vue';
import i18n from '@/i18n';
import TemplateHome from '@/components/templates/home/TemplateHome.vue';

export default Vue.extend({
  name: 'Home',
  components: { TemplateHome },
  metaInfo: {
    title: ` - ${i18n.t('tabs.home')}`,
  },
});
