
import Vue from 'vue';
import { BvModal } from 'bootstrap-vue';
import VueLottiePlayer from 'vue-lottie-player';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import InlineSvg from 'vue-inline-svg';
import { throttle } from 'lodash';

import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomWell from '@/components/atoms/AtomWell.vue';
import AtomEventsCalendar, {
  CalendarEvent,
  CHANGE_EVENT_NAME as SELECTED_DATE_CHANGED,
} from '@/components/atoms/AtomEventsCalendar.vue';
import MoleculeDateSection from '@/components/molecules/date/MoleculeDateSection.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismUnMatchedRide from '@/components/organisms/home/OrganismUnMatchedRide.vue';
import OrganismMatchedRide from '@/components/organisms/home/OrganismMatchedRide.vue';
import OrganismFirstSteps from '@/components/organisms/first-steps/OrganismFirstSteps.vue';
import OrganismEnterCode from '@/components/organisms/first-steps/OrganismEnterCode.vue';
import OrganismPhoneValidation from '@/components/organisms/phone-validation/OrganismPhoneValidation.vue';
import OrganismSiteAssignmentModal from '@/components/organisms/site-assignment/OrganismSiteAssignmentModal.vue';

import RideSummaryModel from '@/models/ride/RideSummaryModel';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import SiteModel from '@/models/site/SiteModel';
import CampaignModel, { CampaignEnum } from '@/models/campaigns/CampaignModel';

import RideTypeEnum from '@/enums/rides/RideTypeEnum';
import TripEnum from '@/enums/rides/RolesEnum';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';

import { monthLiterals, dayAbbreviations, useAmPm, useIsoFormat } from '@/common/dateCalculations';

import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import { hasFeature } from '@/services/feature/FeatureService';
import { sortMomentAsc, sortMomentDesc } from '@/services/utilities/sort';
import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';

import { getSites } from '@/api/site/siteApi';
import { getCampaigns } from '@/api/campaigns/campaignsApi';

import RouteNamesEnum from '@/router/RouteNamesEnum';

const HEADER_HEIGHT_PX = 68;

export default Vue.extend({
  components: {
    VueLottiePlayer,
    InlineSvg,
    AtomActionSelector,
    AtomButton,
    AtomSvgIcon,
    AtomText,
    AtomWell,
    AtomNotification,
    AtomEventsCalendar,
    MoleculeDateSection,
    MoleculeModal,
    OrganismPageSubHeader,
    OrganismUnMatchedRide,
    OrganismMatchedRide,
    OrganismFirstSteps,
    OrganismEnterCode,
    OrganismPhoneValidation,
    OrganismSiteAssignmentModal,
  },
  name: 'TemplateHome',
  data: () => ({
    NotificationVariant,
    ButtonVariant,
    FeatureEnum,
    Features,
    TripEnum,
    RouteNamesEnum,
    today: moment(),
    selectedDay: moment().startOf('day'),
    days: [] as moment.Moment[],
    showPastRides: false,
    SELECTED_DATE_CHANGED,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callToActionId: null as any,
    // eslint-disable-next-line
    feedScrollEvent: (ev) => {},
    sites: [] as SiteModel[],
    campaigns: [] as CampaignModel[],
    selectedCampaign: null as null | CampaignModel,
    isPhoneValidationSkipped: localStorage.getItem('SKIP_PHONE_VALIDATION') === 'true',
    isLoading: true,
  }),
  methods: {
    ...mapActions('rides', ['fetchIntents', 'fetchCommunityIntents', 'fetchRides', 'fetchUnratedRides']),
    ...mapActions('user', ['fetchBlockedUsers']),
    hasFeature,
    isEnvReady,
    hidePhoneNotification() {
      this.isPhoneValidationSkipped = true;
    },
    openPhoneValidation() {
      this.$bvModal.show('tg-modal--phone-validation');
    },
    closePhoneValidation() {
      this.$bvModal.show('tg-modal--phone-validation');
    },
    handleCampaignDetails(campaign: CampaignModel) {
      this.selectedCampaign = campaign;
      this.$bvModal.show('tg-modal--campaign-details');
    },
    getCampaignIcon(type: CampaignEnum): NotificationVariant {
      if (type === CampaignEnum.PROMOTION) return NotificationVariant.CAMPAIGN_PROMOTION;
      if (type === CampaignEnum.EVENT) return NotificationVariant.CAMPAIGN_EVENT;
      if (type === CampaignEnum.GAMIFICATION) return NotificationVariant.CAMPAIGN_GAMIFICATION;

      return NotificationVariant.INFORMATION;
    },
    tabToggle(state: boolean) {
      this.showPastRides = state;
      const today = moment.now();
      let date: moment.Moment;
      if (this.showPastRides) {
        date = this.getPastRidesDates[0] || today;
      } else {
        date = this.futureRideDates[0] || today;
      }
      setTimeout(() => this.scrollIntoView(date, 'auto'), 10);
    },
    addDays(date: moment.Moment, days: number): moment.Moment {
      return date.clone().add(days, 'days');
    },
    isTimeGap(dayIndex: number): boolean {
      const { days } = this;
      if (dayIndex < days.length - 1) {
        const currentDay = days[dayIndex];
        const nextDay = days[dayIndex + 1];
        if (nextDay.diff(currentDay, 'days') > 1) return true;
      }

      return false;
    },
    fillTimeGap(dayIndex: number): void {
      const { futureRideDates, loadDateFullWeek } = this;
      if (dayIndex < futureRideDates.length - 1) {
        const dayOfPrevWeek = futureRideDates[dayIndex + 1].clone().subtract(1, 'day');
        loadDateFullWeek(dayOfPrevWeek);
      }
    },
    initFirstWeek() {
      const { dateIndexInWeek, selectedDate } = this;
      const dayIndex = dateIndexInWeek(selectedDate);

      let end = 7;
      if (this.useIsoFormat) {
        const index = dayIndex || 7;
        end = index === 1 ? 6 : 7 + (7 - index);
      } else {
        end = dayIndex === 7 ? 6 : 7 + (6 - dayIndex);
      }
      for (let i = 0; i <= end; i += 1) {
        this.days.push(this.addDays(selectedDate, i));
      }
    },
    tripsPerDay(rides: RideSummaryModel[], date: moment.Moment, pastDays = false): RideSummaryModel[] {
      return rides
        .filter(
          ({ destination: { dateTime: latestArrivalDateTime } }) => {
            if (pastDays) return latestArrivalDateTime.isSame(date, 'day') && latestArrivalDateTime.isBefore(moment());

            return latestArrivalDateTime.isSame(date, 'day') && latestArrivalDateTime.isAfter(moment());
          },
        )
        .sort((
          { origin: { dateTime: a } },
          { origin: { dateTime: b } },
        ) => (pastDays ? sortMomentDesc(a, b) : sortMomentAsc(a, b)));
    },
    getComponentName(ride: RideSummaryModel): string {
      switch (ride.type) {
        case RideTypeEnum.MATCHED:
          return 'OrganismMatchedRide';
        case RideTypeEnum.UNMATCHED:
        default:
          return 'OrganismUnMatchedRide';
      }
    },
    getDaysLiterals: () => dayAbbreviations(),
    getMonthsLiterals: () => monthLiterals(),
    handleDateChange(date: moment.Moment) {
      this.selectedDate = moment(date).startOf('day');
      const { selectedDate, days: dates, loadDateFullWeek } = this;

      if (this.selectedDate.isSameOrAfter(this.today, 'day')) {
        this.showPastRides = false;
        if (!dates.some((d) => d.isSame(selectedDate, 'day'))) {
          loadDateFullWeek(selectedDate);
        }
      } else {
        this.showPastRides = true;
      }
      setTimeout(() => this.scrollIntoView(date), 0);
    },
    getFirstDayOfWeek(dayIndex: number, date: moment.Moment) {
      if (this.useIsoFormat) {
        const index = dayIndex || 7;
        return this.addDays(date, -(index - 1));
      }
      const index = dayIndex === 7 ? 0 : dayIndex;

      return this.addDays(date, index);
    },
    loadDateFullWeek(date: moment.Moment) {
      const startOfWeekDay = this.getFirstDayOfWeek(this.dateIndexInWeek(date), date);
      for (let i = 0; i < 7; i += 1) {
        this.days.push(this.addDays(startOfWeekDay, i));
      }
    },
    loadMore() {
      const { futureRideDates } = this;
      const lastDate = futureRideDates[futureRideDates.length - 1];

      for (let i = 1; i <= 7; i += 1) {
        this.days.push(lastDate.clone().add(i, 'days'));
      }
    },
    displayRole(role: TripEnum, type: RideTypeEnum): string {
      if (role === TripEnum.DRIVER && type === RideTypeEnum.UNMATCHED) return this.$t('rideIntent.role.passenger').toString();
      if (role === TripEnum.DRIVER && type === RideTypeEnum.MATCHED) return this.$t('rideIntent.role.driver').toString();
      if (role === TripEnum.PASSENGER && type === RideTypeEnum.UNMATCHED) return this.$t('rideIntent.role.driver').toString();
      if (role === TripEnum.PASSENGER && type === RideTypeEnum.MATCHED) return this.$t('rideIntent.role.passenger').toString();
      if (role === TripEnum.DRIVER_AND_PASSENGER) return this.$t('rideIntent.role.driverOrPassenger').toString();
      return '';
    },
    scrollIntoView(date: moment.Moment, behavior = 'smooth' as (ScrollBehavior|undefined)) {
      if (window.scrollY > HEADER_HEIGHT_PX) {
        window.scroll(0, HEADER_HEIGHT_PX);
      }
      // let prevFeedTopOffset = 0;
      const { feedContainer } = this.$refs as { feedContainer: Element };
      const feedContainerTop = feedContainer.getBoundingClientRect().top;
      const cardRef = this.$refs[`date-${date.format('DD-MM-Y')}`];
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const cardTopOffset = cardRef[0].$el.getBoundingClientRect().top;

      feedContainer.scroll({ top: cardTopOffset + feedContainer.scrollTop - feedContainerTop, behavior });
    },
    dateIndexInWeek(date: moment.Moment): number {
      return this.useIsoFormat ? date.day() : date.isoWeekday();
    },
    // TODO: Refactor this local error status check -> create a generic service for it
    checkResponseStatus(response) {
      const { status } = response;
      // Informative answers
      if (status >= 100 && status <= 199) {
        return;
      }
      // Successful answers
      if (status >= 200 && status <= 299) {
        return;
      }
      // Redirects
      if (status >= 300 && status <= 399) {
        this.displayErrorToast('');
        return;
      }
      // Client-Errors
      if (status >= 400 && status <= 499) {
        this.displayErrorToast('Client Error: ');
        return;
      }
      // Server-Errors
      if (status >= 500 && status <= 599) {
        this.displayErrorToast('Server Error: ');
        return;
      }
      this.displayErrorToast('');
    },
    displayErrorToast(message: string) {
      toast(
        this.$bvToast,
        message + this.$t('rideIntent.messages.error').toString(),
        ToastVariants.DANGER,
      );
    },
    isPartOfSeries(id) {
      const intent = this.getIntentById(id);
      if (intent.recurringIntentId === null) {
        return false;
      }
      return true;
    },
    parkingSpace(role: TripEnum, intentId: string) {
      const intent = this.getIntentById(intentId) as RideIntentModel;
      return (role !== TripEnum.PASSENGER && hasFeature(this.features, FeatureEnum.PARKING_OPTIONS)
        ? intent.parkingSpace
        : null);
    },
    showOrganizationAssignment(status: boolean, bvModal: BvModal) {
      if (status) bvModal.show('tg-modal--enter-code');
      else bvModal.hide('tg-modal--enter-code');
    },
    async onOrganisationChange() {
      await this.fetchSites();
      this.showSiteAssignment(
        (this.hasUserLoaded && this.sites.length > 1 && !this.b2cFree && !(this.properties.hasCompletedSiteAssignment === 'true')),
        this.$bvModal,
      );
    },
    async showSiteAssignment(status: boolean, bvModal: BvModal) {
      if (status) bvModal.show('tg-modal--user-site-assignment');
      else bvModal.hide('tg-modal--user-site-assignment');
    },
    async fetchSites() {
      const { data = [] } = await getSites(this.getUserId);
      this.sites = data;
    },
  },
  computed: {
    ...mapGetters('user', [
      'getCidaasUserData',
      'getAccessToken',
      'getUserId',
      'communityId',
      'isRegistrationCompleted',
      'isPhoneVerified',
      'hasPhoneNumber',
      'isEmailVerified',
      'hasHomePoi',
      'properties',
      'features',
      'b2cFree',
      'hasUserLoaded',
    ]),
    ...mapGetters('rides', {
      intents: 'getIntents',
      communityIntents: 'getCommunityIntents',
      rides: 'getSummary',
      unRatedRides: 'getGetUnratedRidesById',
      hasUnratedRides: 'hasUnratedRides',
    }),
    ...mapGetters('rides', ['getIntentById']),
    selectedDate: {
      get(): moment.Moment {
        return this.selectedDay;
      },
      set(date: moment.Moment) {
        this.selectedDay = moment(date).startOf('day');
      },
    },
    calendarEvents(): CalendarEvent[] {
      const { rides }: { rides: RideSummaryModel[] } = this;

      return rides.map((trip) => ({
        date: trip.origin.dateTime,
        type: !this.unRatedRides(trip.matchId).length ? trip.eventType : EventTypeEnum.ACTION_REQUIRED,
        tooltip: trip.tooltip,
      }));
    },
    futureRideDates(): moment.Moment[] {
      const { days } = this;
      return days.sort(sortMomentAsc);
    },
    getPastRidesDates(): moment.Moment[] {
      const { rides }: { rides: RideSummaryModel[] } = this;
      const now = moment();
      return rides
        .reduce((dates, { destination: { dateTime } }) => {
          if (
            dateTime.isBefore(now) &&
            !dates.some((date) => date.isSame(dateTime, 'day'))
          ) {
            dates.push(dateTime);
          }
          return dates;
        }, [] as moment.Moment[])
        .sort(sortMomentDesc);
    },
    useAmPm(): boolean {
      return useAmPm(this.$i18n.locale);
    },
    useIsoFormat(): boolean {
      return useIsoFormat(navigator.language);
    },
    hasOrganizationAssigned(): boolean {
      return (
        this.b2cFree
        && !(this.properties.hasCompletedOrganizationAssignment === 'true')
        && this.isRegistrationCompleted
      );
    },
    hasSiteAssigned(): boolean {
      return (
        this.sites.length > 1
        && !(this.properties.hasCompletedSiteAssignment === 'true')
        && this.isRegistrationCompleted
        && !this.b2cFree
      );
    },
  },
  async mounted() {
    this.isLoading = true;
    const { eventType } = this.$route.params;
    if (eventType === EventTypeEnum.PAST_MATCH || eventType === EventTypeEnum.PAST_RIDE_INTENT) {
      this.tabToggle(true);
    }
    let feedTopOffset = 0;
    this.feedScrollEvent = throttle((ev) => {
      // eslint-disable-next-line
      const [firstElInList, ...rest] = ev.target.getElementsByClassName('molecule-date-wrapper');
      const newFeedTopOffset = firstElInList ? firstElInList.getBoundingClientRect().top : 0;
      if (feedTopOffset >= newFeedTopOffset) {
        window.scroll({ top: HEADER_HEIGHT_PX, behavior: 'smooth' });
      } else {
        window.scroll({ top: 0, behavior: 'smooth' });
      }
      feedTopOffset = newFeedTopOffset;
    }, 200);

    this.initFirstWeek();

    // await deleteUserProperty(this.getUserId, 'hasCompletedSiteAssignment');
    if (!this.isRegistrationCompleted) {
      this.$bvModal.show('tg-modal--first-steps');
    } else {
      this.checkResponseStatus(await this.fetchIntents(this.getUserId));
      this.checkResponseStatus(await this.fetchRides(this.getUserId));
    }

    const { data: campaigns } = await getCampaigns(this.getUserId);
    this.campaigns = campaigns;

    this.showSiteAssignment(this.hasSiteAssigned, this.$bvModal);

    this.fetchBlockedUsers(this.getUserId);
    this.fetchUnratedRides(this.getUserId);

    if (this.properties.hasCompletedSiteAssignment !== 'true') {
      this.fetchSites();
    }
    this.isLoading = false;
  },
  watch: {
    hasUserLoaded(status) {
      if (status) {
        this.showOrganizationAssignment(this.hasOrganizationAssigned, this.$bvModal);
        this.showSiteAssignment(this.hasSiteAssigned, this.$bvModal);
        this.fetchCommunityIntents(this.communityId);
      }
    },
    sites(sites) {
      if (sites.length > 1) this.showSiteAssignment(this.hasSiteAssigned, this.$bvModal);
    },
  },
});
