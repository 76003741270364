
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomInput from '@/components/atoms/AtomInput.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import OrganismFirstStepsSkeleton from './OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsPhone',
  components: { VueLottiePlayer, AtomButton, OrganismFirstStepsSkeleton, AtomInput },
  data: () => ({
    ButtonVariant,
    phone: undefined as string|undefined,
  }),
  methods: {
    saveAndNext() {
      this.$emit('selection', this.phone);
      this.$emit('next', true);
    },
  },
});
