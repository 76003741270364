import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, SITE_SERVICE_URL } from '@/common/config';
import SiteResponse from '@/models/site/response/SiteResponse';
import mapSiteResponseToModel from '@/models/site/MapSiteResponseToModel';

export const getSites = async (userId: string): Promise<SiteResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(
      `${APIGEE_DOMAIN}/${SITE_SERVICE_URL}/sites/suggest`,
      options,
    );
    const sites = (data.result || []).map(mapSiteResponseToModel);

    return {
      status,
      data: sites,
    };
  } catch (error) {
    return { status: 404 };
  }
};

export default getSites;
