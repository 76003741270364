
import Vue, { PropType } from 'vue';
import { BTooltip } from 'bootstrap-vue';
import moment from 'moment';
import { getCalendarViewDays, isValidDate } from '@/common/dateCalculations';

import EventType from '@/enums/rides/EventsTypeEnum';

import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';

export const CHANGE_EVENT_NAME = 'datechange';

export interface CalendarEvent {
  date: moment.Moment;
  type: EventType;
  tooltip?: string;
}

export default Vue.extend({
  name: 'AtomEventsCalendar',
  components: { BTooltip, AtomSvgIcon, AtomButton },
  props: {
    // used for the headline besides the year and to not bind the component itself to translations
    monthLiterals: {
      type: Array as PropType<string[]>,
      required: true,
    },
    // same as with month literals, expected order is monday -> sunday
    dayLiterals: {
      type: Array as PropType<string[]>,
      required: true,
    },
    // date to be selected at the start
    initialDate: {
      type: moment,
      required: false,
    },
    // date to be selected at the start
    events: {
      type: Array as PropType<CalendarEvent[]>,
      required: false,
      default: () => [],
    },
    // isoWeek means the week starts with monday
    isoWeek: {
      type: Boolean,
      required: false,
    },
    // the latest valid date (including the date stated), used for styling and being selectable
    maximumDate: {
      type: moment,
      required: false,
    },
  },
  data: () => ({
    ButtonVariant,
    internalDate: null as moment.Moment | null,
    internalMonth: null as moment.Moment | null,
    multipleSelects: [] as moment.Moment[],
  }),
  computed: {
    monthAndYear(): string {
      return `${this.monthLiterals[this.selectedMonth.month()]} ${this.selectedMonth.year()}`;
    },
    calendarView(): moment.Moment[][] {
      return getCalendarViewDays(moment(this.selectedMonth), this.isoWeek);
    },
    sortedDayLiterals(): string[] {
      if (this.isoWeek) {
        return this.dayLiterals;
      }
      return [this.dayLiterals[6]].concat(this.dayLiterals.slice(0, 6));
    },
    selectedDate: {
      get(): moment.Moment {
        return this.internalDate || moment(this.initialDate);
      },
      set(date: moment.Moment) {
        this.internalDate = moment(date);
        this.$emit(CHANGE_EVENT_NAME, moment(date));
      },
    },
    todaysDate: {
      get(): moment.Moment {
        return moment().startOf('day');
      },
    },
    selectedMonth: {
      get(): moment.Moment {
        return this.internalMonth || moment(this.initialDate);
      },
      set(date: moment.Moment) {
        this.internalMonth = moment(date);
      },
    },
  },
  methods: {
    getEventClass(evType: EventType): string {
      if (EventType.ACTION_REQUIRED === evType) return 'action-required';
      if (EventType.PAST_RIDE_INTENT === evType) return 'past-ride-intent';
      if (EventType.FUTURE_RIDE_INTENT === evType) return 'future-ride-intent';
      if (EventType.PAST_MATCH === evType) return 'past-match';
      if (EventType.FUTURE_MATCH === evType) return 'future-match';
      return '';
    },
    getEvents(date: moment.Moment): object[] {
      return this.events
        .filter((event) => date.isSame(event.date, 'day'))
        .map(({ type, date: evDate, tooltip }) => ({
          tooltip,
          class: this.getEventClass(type),
          id: evDate.unix(),
        }));
    },
    selectPreviousMonth(): void {
      const targetValue = moment(this.selectedMonth).subtract(1, 'months');
      // check if we would navigate before the month of the minimum value
      this.selectedMonth = targetValue;
    },
    selectNextMonth(): void {
      // moments are mutable, so Vue wouldn't catch any changes if we didn't create a new object
      this.selectedMonth = this.selectedMonth.add(1, 'months').clone();
    },
    selectNextDay(): void {
      if (!this.selectedDate.isSame(this.selectedMonth, 'month')) return;
      console.log(1);
      const targetValue = moment(this.selectedDate).add(1, 'day').clone();

      if (targetValue.isSameOrAfter(this.todaysDate, 'day')) {
        this.selectedDate = targetValue;
      } else {
        const pastDates = this.events
          .filter(
            (event) =>
              event.date.isBefore(this.todaysDate) && event.date.isAfter(this.selectedDate),
          )
          .map(({ date }) => date);
        if (pastDates.length) {
          this.selectedDate = moment.min(pastDates);
        } else {
          this.selectedDate = this.todaysDate;
        }
      }
      if (!this.selectedDate.isSame(this.selectedMonth, 'month')) {
        this.selectNextMonth();
      }
    },
    selectPreviousDay(): void {
      if (!this.selectedDate.isSame(this.selectedMonth, 'month')) return;

      const targetValue = moment(this.selectedDate).subtract(1, 'day').clone();

      if (targetValue.isSameOrAfter(this.todaysDate, 'day')) {
        this.selectedDate = targetValue;
      } else {
        const pastDates = this.events
          .filter(
            (event) =>
              event.date.isBefore(this.todaysDate) && event.date.isBefore(this.selectedDate),
          )
          .map(({ date }) => date);
        if (pastDates.length) {
          this.selectedDate = moment.max(pastDates);
        }
      }
      if (!this.selectedDate.isSame(this.selectedMonth, 'month')) {
        this.selectPreviousMonth();
      }
    },
    selectToday(): void {
      this.selectedDate = moment().startOf('day').clone();
      if (!this.selectedDate.isSame(this.selectedMonth, 'month')) {
        this.selectedMonth = this.selectedDate;
      }
    },
    calculateDateCellClasses(date: moment.Moment): object {
      return {
        inactive:
          !date.isSame(this.selectedMonth, 'month') ||
          !isValidDate(date, 'day', this.todaysDate, this.maximumDate),
        selected: date.isSame(this.selectedDate, 'day'),
        current: date.isSame(this.todaysDate, 'day'),
      };
    },
    dateSelectHandler(date: moment.Moment): void {
      const isDateValid = isValidDate(date, 'day', this.todaysDate, this.maximumDate);
      const hasEvent = this.events.some((e) => e.date.isSame(date, 'day'));
      if (isDateValid || hasEvent) {
        this.selectedDate = moment(date);
        if (!this.selectedDate.isSame(this.selectedMonth, 'month')) {
          this.selectedMonth = this.selectedDate;
        }
      }
    },
    isCurrentWeek(week: moment.Moment[]): boolean {
      return week.some((date) => date.isSame(this.selectedDate, 'day'));
    },
  },
  watch: {
    initialDate(newVal: moment.Moment): void {
      this.internalMonth = moment(newVal);
      this.internalDate = moment(newVal);
    },
  },
});
