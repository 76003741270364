import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, CAMPAIGNS_SERVICE_URL } from '@/common/config';
import { CampaignsResponse } from '@/models/campaigns/response/CampaignResponseModel';
import { mapCampaignResponseToModel } from '@/models/campaigns/mapCampaignResponseToModel';

export const getCampaigns = async (userId: string): Promise<CampaignsResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(
      // eslint-disable-next-line max-len
      `${APIGEE_DOMAIN}/${CAMPAIGNS_SERVICE_URL}/campaigns/contents?userId=${userId}&channel=webUI&onlyUnconfirmed=true`,
      options,
    );
    const campaigns = (data.result || []).map(mapCampaignResponseToModel);

    return {
      status,
      data: campaigns,
    };
  } catch (error) {
    return { data: [], status: 404 };
  }
};

export default getCampaigns;
