
import Vue from 'vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsSkeleton',
  props: {
    toggleOverlay: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AtomSvgIcon,
  },
  computed: {
    hasOverlay(): boolean {
      return !!this.$slots.overlay;
    },
    getClasses(): { [key: string]: boolean } {
      return {
        'first-steps--overlay': true,
        hidden: !this.toggleOverlay,
      };
    },
  },
  methods: {
    closeOverlayAction() {
      this.$emit('closeOverlay');
    },
  },
});
