
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomButton from '@/components/atoms/AtomButton.vue';
import OrganismFirstStepsSkeleton from './OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsWelcome',
  components: { VueLottiePlayer, AtomButton, OrganismFirstStepsSkeleton },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
