import moment from 'moment';
import CampaignModel, { CampaignEnum } from './CampaignModel';
import { CampaignResponseModel } from './response/CampaignResponseModel';

export const mapCampaignResponseToModel = (campaign: CampaignResponseModel): CampaignModel => ({
  ...campaign,
  id: campaign.campaignId,
  startDate: moment(campaign.startDate),
  expirationDate: moment(campaign.expirationDate),
  type: campaign.type as CampaignEnum,
});

export default mapCampaignResponseToModel;
