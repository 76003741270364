
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomAddImage from '@/components/atoms/AtomAddImage.vue';
import OrganismFirstStepsSkeleton from './OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsProfilePicture',
  components: {
    VueLottiePlayer,
    AtomButton,
    OrganismFirstStepsSkeleton,
    AtomAddImage,
  },
  data: () => ({
    ButtonVariant,
    isOverlayOpened: false,
  }),
  methods: {
    handleImageUpload(croppedImg: string): void {
      // Todo: Implement BE connection when user profile wrapper is ready.
      this.$emit('selection', croppedImg);
      this.$emit('next');
    },
    toggleOverlay(status: boolean): void {
      this.isOverlayOpened = status;
    },
  },
});
