
import Vue from 'vue';
import VueLottiePlayer from 'vue-lottie-player';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import OrganismFirstStepsSkeleton from './OrganismFirstStepsSkeleton.vue';

export default Vue.extend({
  name: 'OrganismFirstStepsFinished',
  components: { VueLottiePlayer, AtomButton, OrganismFirstStepsSkeleton },
  data: () => ({
    ButtonVariant,
  }),
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
